import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";
import { PageParams } from "../../../types";
import ALink from "../../components/a-link/a-link";
import Hero from "../../components/hero/hero";
import Logo from "../../images/svg/aplinet_logo_text.inline.svg";

import "./outsourcing.scss";

const OutsourcingPage = (params: PageParams) => {
  const language = params.pageContext.language;
  return (
    <>
      <SEO title="Outsourcing | Agencja Interaktywna Aplinet" />
      <Hero>
        <div className="container-xs header-padding">
          <h1>Oferta | Agencja Interaktywna Aplinet</h1>
          <div className="header-subline">
            <h2 className="subtitle">Nasze doświadczenie w komercyjnych projektach</h2>
          </div>
        </div>
      </Hero>

      {/* -----------------------------------------` */}
      {/* -----------------------------------------` */}

      <ALink to="/" language={language}>
        ` Powrót
      </ALink>
    </>
  );
};

export default OutsourcingPage;
